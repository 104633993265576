import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Navigate } from 'react-router-dom'
import { LoadingPage } from '../../MDS-web-ui/src'
import { ActiveLink } from '../Link'
import { makeObservable } from 'mobx'

const withAuthorization = condition => Component => {
  @inject('sessionStore')
  @inject('routing')
  @observer
  class WithAuthentication extends React.Component {
    static propTypes = {
      sessionStore: PropTypes.object.isRequired,
      routing: PropTypes.object.isRequired
    }

    constructor(props) {
      super(props)
      makeObservable(this)
    }

    render() {
      const { sessionStore, routing } = this.props
      const hasAuthorization = condition(sessionStore.authUser)
      const loading = sessionStore.loading
      const { location } = routing

      return loading ? (
        <LoadingPage LinkComponent={ActiveLink} />
      ) : hasAuthorization ? (
        <Component />
      ) : (
        <Navigate
          to={{
            pathname: '/login',
            state: { from: location }
          }}
          replace
        />
      )
    }
  }
  return WithAuthentication
}

export default withAuthorization
