import { makeObservable, observable } from 'mobx'

export default class PreferenceModel {
  key

  title
  description
  value

  constructor(key, title, description, value) {
    this.key = key
    this.title = title
    this.description = description
    this.value = value

    makeObservable(this, {
      title: observable,
      description: observable,
      value: observable
    })
  }

  setValue(value) {
    this.value = value
  }

  toJS() {
    return {
      key: this.key,
      title: this.title,
      description: this.description,
      value: this.value
    }
  }
}
