import React, { Component, Suspense } from 'react'
import { Provider as StoreProvider } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from '@ibm/mobx-react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { createGlobalStyle } from 'styled-components'
import { ErrorBoundary } from './error/ErrorBoundary'

import PreferenceStore from './stores/PreferenceStore'
import ViewStore from './stores/ViewStore'
import SessionStore from './stores/SessionStore'

import { ActiveLink } from './components/Link'
import { ThemeProvider, LoadingPage } from './MDS-web-ui/src'

import Main from './components/Main'
import getEnv from './env'

export const GlobalStyles = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-size-adjust: 100%;
  }
  body {
    margin: 0;
  }
`

const prefStore = new PreferenceStore()
const viewStore = new ViewStore()
const sessionStore = new SessionStore()

// Router
const browserHistory = createBrowserHistory()
const routingStore = new RouterStore()
const history = syncHistoryWithStore(browserHistory, routingStore)

const stores = { prefStore, viewStore, routing: routingStore, sessionStore }

export default class App extends Component {
  componentDidMount() {
    const environment = getEnv()
    console.log(`Environment: ${environment}`)
  }
  render() {
    return (
      <ThemeProvider>
        <StoreProvider {...stores}>
          <ErrorBoundary>
            <Router location={routingStore.location} navigator={history}>
              <Suspense fallback={<LoadingPage LinkComponent={ActiveLink} />}>
                <>
                  <Main />
                  <GlobalStyles />
                </>
              </Suspense>
            </Router>
          </ErrorBoundary>
        </StoreProvider>
      </ThemeProvider>
    )
  }
}
