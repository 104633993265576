import { makeObservable, observable } from 'mobx'

export default class ViewStore {
  preferenceBeingEdited = null
  // Persist new email on reauth
  preferenceTemporaryValue = null

  constructor() {
    makeObservable(this, {
      preferenceBeingEdited: observable,
      preferenceTemporaryValue: observable
    })
  }
}
