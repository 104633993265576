import React from 'react'
import { GridCentered } from '../Grid'
import { LoadingAnimation } from './LoadingAnimation'

/**
 * LoadingWrapper component.
 *
 * This component conditionally renders its children or a loading animation based on the `loading` prop.
 * If `loading` is true and `showAnimation` is true, it displays a loading animation.
 * If `loading` is true and `showAnimation` is false, it displays a blank div.
 * If `loading` is false, it displays the children.
 *
 * @component
 * @example
 * // Usage example:
 * <LoadingWrapper loading={true} showAnimation={true}>
 *   <YourComponent />
 * </LoadingWrapper>
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {boolean} props.loading - Determines whether the loading state is active.
 * @param {boolean} [props.showAnimation=false] - Determines whether to show the loading animation.
 * @param {React.ReactNode} props.children - The children to render when not loading.
 * @returns {JSX.Element} The LoadingWrapper component.
 */
export const LoadingWrapper = ({ loading, children, showAnimation = false }) => {
  if (loading) {
    if (showAnimation) {
      // show animation while loading
      return (
        <GridCentered>
          <LoadingAnimation />
        </GridCentered>
      )
    } else {
      // blank while loading
      return <div />
    }
  } else {
    return <div>{children}</div>
  }
}

export default LoadingWrapper
