import styled from 'styled-components'
import { color, layout, space, typography } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

export const InlineLink = styled.a
  .withConfig({
    shouldForwardProp,
  })
  .attrs((props) => ({
    lineHeight: [2, 3],
    ...props,
  }))(
  {
    color: 'inherit',
    display: 'inline-block',
    textDecoration: 'none',
    transition: 'opacity ease-in-out 0.1s',
    '&:hover': {
      opacity: 0.8,
    },
  },
  space,
  layout,
  color,
  typography,
)
