import { themeGet } from '@styled-system/theme-get'
import { saturate, darken } from 'polished'
import styled from 'styled-components'
import { color, space, typography, border } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

/**
 * A styled button component.
 *
 * @component
 * @example
 * // Usage example:
 * <Button onClick={handleClick}>Click Me</Button>
 */
export const Button = styled.button
  .withConfig({
    shouldForwardProp,
  })
  .attrs((props) => ({
    fontSize: 1,
    bg: 'sidebarblue',
    px: [3, 4],
    py: [2, 3],
    ...props,
  }))(
  {
    fontWeight: 'normal',
    lineHeight: 16 / 14,
    color: 'aqua',
    borderRadius: 4,
    borderColor: 'gray',
  },
  (props) => ({
    fontFamily: 'inherit',
    WebkitFontSmoothing: 'antialiased',
    display: 'inline-block',
    verticalAlign: 'middle',
    textAlign: 'center',
    textDecoration: 'none',
    appearance: 'none',
    userSelect: 'none',
    cursor: 'pointer',
    border: '1px solid black',
    textTransform: props.uppercase ? 'uppercase' : 'initial',
    '&:disabled': {
      opacity: 1 / 3,
      cursor: 'default',
    },
  }),
  (props) => {
    const buttonbg = themeGet('colors.' + props.bg, props.bg)(props)
    const baseColor = saturate(0.5, buttonbg)

    return {
      borderColor: `${themeGet('colors.' + props.borderColor, props.borderColor)(props)}`,

      '&:hover:not(:disabled)': {
        backgroundColor: `${darken(0.05, baseColor)}`,
      },
      '&:active': {
        backgroundColor: `${darken(0.1, baseColor)}`,
      },
      '&:focus': {
        outline: 'none',
      },
      '&:focus:not(:active)': {
        backgroundColor: `${darken(0.15, baseColor)}`,
      },
    }
  },
  typography,
  space,
  color,
  border,
)

Button.displayName = 'Button'
