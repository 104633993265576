import React, { useCallback } from 'react'
import styled from 'styled-components'
import { color, layout, space, typography } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { PrimaryButton, SecondaryButton, InlineLink } from '../MDS-web-ui/src'

import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
  useNavigate
} from 'react-router-dom'

const CleanRouterLink = styled(RouterLink)
  .withConfig({
    shouldForwardProp
  })
  .attrs(props => ({
    lineHeight: [2, 3],
    color: 'inherit',
    ...props
  }))(
  {
    display: 'inline-block',
    textDecoration: 'none',
    transition: 'opacity ease-in-out 0.1s',
    '&:hover': {
      opacity: 0.8
    }
  },
  space,
  layout,
  color,
  typography
)

export const DefaultLink = CleanRouterLink

export const Link = ({
  LinkComponent = InlineLink,
  isActive,
  external = false,
  to,
  inline = false,
  newTab = false,
  ...props
}) => {
  const LinkComponentImpl = external ? LinkComponent : DefaultLink
  const linkProps = { ...props }

  if (external) {
    // Anchor tag by default
    linkProps.href = to
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    linkProps.rel = 'noopener'
  } else {
    // react-router
    linkProps.to = to
  }
  if (newTab) {
    linkProps.target = '_blank'
  }
  if (isActive) {
    linkProps.fontWeight = 'bold'
  }

  if (inline) {
    return <LinkComponentImpl {...linkProps} />
  } else {
    return (
      <div>
        <LinkComponentImpl {...linkProps} />
      </div>
    )
  }
}

Link.displayName = 'Link'

///////////////////////////////////////////////////////////////////////////////
// Active Link - passes isActive down to Link element
export const ActiveLink = ({ to, exact = true, ...rest }) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: exact })

  return <Link to={to} {...rest} isActive={!!match} />
}

///////////////////////////////////////////////////////////////////////////////
// for linking to local urls e.g. href='/'
export const ButtonLinkLocal = ({ href, type = 'secondary', ...rest }) => {
  let navigate = useNavigate()

  // TODO check modifier keys pressed
  const onClick = useCallback(() => navigate(href), [navigate, href])

  if (type === 'secondary') {
    return <SecondaryButton {...rest} as="a" onClick={onClick} />
  } else if (type === 'primary') {
    return <PrimaryButton {...rest} as="a" onClick={onClick} />
  }

  throw Error('Invalid ButtonLinkLocal type')
}
