import React from 'react'
import { Heading } from '../Heading'
import { SideBySideLayout } from '../SideBySideLayout'
import { LoadingIcon } from './LoadingIcon'

/**
 * LoadingPage component.
 *
 * This component renders SideBySideLayout with a Heading and LoadingIcon.
 *
 * @component
 * @example
 * // Usage example:
 * <LoadingPage />
 *
 * @returns {JSX.Element} The LoadingPage component.
 */
export const LoadingPage = (props) => (
  <SideBySideLayout {...props}>
    <Heading>Loading</Heading>
    <LoadingIcon />
  </SideBySideLayout>
)

export default LoadingPage
