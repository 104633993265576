import React, { Component, lazy } from 'react'
import { Route, Navigate, Routes } from 'react-router-dom'
import * as Sentry from '@sentry/react'

// Logic
import Missing from './Missing'

import withAuthentication from './auth/withAuthentication'
import withAuthorization from './auth/withAuthorization'

const Account = lazy(() =>
  import(/* webpackChunkName: "account" */ './Account')
)
const Profile = lazy(() =>
  import(/* webpackChunkName: "profile" */ './Profile')
)
const Success = lazy(() =>
  import(/* webpackChunkName: "success" */ './Success')
)
const Redeem = lazy(() => import(/* webpackChunkName: "redeem" */ './Redeem'))
const Login = lazy(() => import(/* webpackChunkName: "login" */ './Login'))
const Logout = lazy(() => import(/* webpackChunkName: "logout" */ './Logout'))
const Privacy = lazy(() =>
  import(/* webpackChunkName: "privacy" */ './Privacy')
)
const ActionsFeedback = lazy(() =>
  import(/* webpackChunkName: "actions-feedback" */ './Feedback/Actions')
)
const TimepageFeedback = lazy(() =>
  import(/* webpackChunkName: "timepage-feedback" */ './Feedback/Timepage')
)
const FlowFeedback = lazy(() =>
  import(/* webpackChunkName: "flow-feedback" */ './Feedback/Timepage')
)

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const loggedIn = user => !!user

@withAuthentication
export default class Main extends Component {
  render() {
    return (
      <SentryRoutes>
        <Route exact path="/" element={<IndexRoute />} />
        <Route exact path="/account" element={<Account />} />
        <Route path="/redeem/:coupon" element={<Redeem />} />
        <Route path="/redeem" element={<Redeem />} />
        <Route path="/success" element={<Success />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/error" render={() => this.error()} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route
          exact
          path="/feedback"
          element={<Navigate to="/feedback/actions" />}
        />
        <Route path="/feedback/actions" element={<ActionsFeedback />} />
        <Route path="/feedback/timepage" element={<TimepageFeedback />} />
        <Route path="/feedback/flow" element={<FlowFeedback />} />
        <Route element={<Missing />} />
      </SentryRoutes>
    )
  }
}

@withAuthorization(loggedIn)
class IndexRoute extends Component {
  render() {
    return <Navigate to="/profile" replace />
  }
}
