import { makeObservable, observable, action } from 'mobx'
import { listenToMembership } from '../firebase'
import moment from 'moment'

export const appKeys = {
  Timepage: {
    key: 'timeline',
    download:
      'https://itunes.apple.com/app/timepage-calendar-by-moleskine/id989178902?mt=8&at=1010ldAj&ct=mdsaccounts',
    support: 'https://bonobolabs.com/support/',
    guide: 'https://bonobolabs.com/support/timepage/',
    contact: 'mailto:timepage@bonobolabs.com',
    description: 'Calendar Planner'
  },
  Actions: {
    key: 'actions',
    download:
      'https://apps.apple.com/app/apple-store/id1227402276?pt=542023&ct=mdshome&mt=8&at=1010ldAj&ct=mdsaccounts',
    web: 'https://actions.bonobolabs.com/',
    support: 'https://bonobolabs.com/support/',
    guide: 'https://bonobolabs.com/support/actions/',
    contact: 'mailto:actions@bonobolabs.com',
    description: 'To Do List Organiser'
  },
  Flow: {
    key: 'flow',
    download:
      'https://apps.apple.com/app/apple-store/id1271361459?pt=542023&ct=mdshome&mt=8&at=1010ldAj&ct=mdsaccounts',
    support: 'https://bonobolabs.com/support/',
    guide: 'https://bonobolabs.com/support/flow/',
    contact: 'mailto:flow@bonobolabs.com',
    description: 'Note Taking, Drawing Pad'
  },
  Overlap: {
    download:
      'https://apps.apple.com/au/app/overlap-by-moleskine-studio/id1516950324'
  }
}

const fromTimestamp = timestamp =>
  // Unix time to moment object (seconds to milliseconds)
  moment.unix(timestamp)

export default class AppMembershipModel {
  name = ''
  loading = true
  membershipTitle = null
  membershipSubtitle = null
  membershipExpired = null
  membershipType = null
  membershipExpiry = null
  membershipAutoRenew = null
  membershipSource = null

  download = null
  support = null
  contact = null
  guide = null
  manageAppleSubscription = 'https://apps.apple.com/account/subscriptions'

  constructor(name, uid) {
    if (!(name in appKeys)) {
      throw new Error('Invalid app name')
    }
    this.name = name

    this.description = appKeys[this.name].description
    this.download = appKeys[this.name].download
    this.support = appKeys[this.name].support
    this.contact = appKeys[this.name].contact
    this.guide = appKeys[this.name].guide

    this.fetch(uid)

    makeObservable(this, {
      name: observable,
      loading: observable,
      membershipTitle: observable,
      membershipSubtitle: observable,
      membershipExpired: observable,
      membershipType: observable,
      membershipExpiry: observable,
      membershipAutoRenew: observable,
      membershipSource: observable,
      setMembership: action
    })
  }

  toJS() {
    return {
      name: this.name
    }
  }

  async fetch(uid) {
    const appName = this.name.toLowerCase()

    listenToMembership(uid, appName, snap => {
      this.onFirebaseFetch(snap.data())
      this.loading = false
    })
  }

  onFirebaseFetch(data) {
    if (!data) {
      return
    }

    this.membershipTitle = data.title
    this.membershipSubtitle = data.subtitle
    this.membershipType = data.type
    this.membershipAutoRenew = data.autorenew
    this.membershipExpiry = fromTimestamp(data.expiry)
    this.membershipExpired = data.expired
    this.membershipCancelled = data.cancelled
    this.membershipSource = data.source
  }

  setMembership(data) {
    for (const membershipKey of Object.keys(data)) {
      this[membershipKey] = data[membershipKey]
    }
  }
}
