import React from 'react'
import DocumentTitle from 'react-document-title'
import { useTranslation } from 'react-i18next'

const PageTitle = ({ page, ...props }) => {
  const { t } = useTranslation()

  const pageTitle = t(`main.${page}`)
  const title = `${pageTitle} · Bonobo Accounts`

  return <DocumentTitle title={title} {...props} />
}

export default PageTitle
