import runtimeEnv from '@mars/heroku-js-runtime-env'

const env = runtimeEnv()

export const serverName = env.REACT_APP_HEROKU_APP_NAME
export const appVersion = env.REACT_APP_HEROKU_RELEASE_VERSION
export const branch = env.REACT_APP_HEROKU_BRANCH

const getAuthDomain = () => {
  if (serverName === 'mds-accounts') {
    return window.location.hostname
    // return 'accounts.moleskinestudio.com'
  }
  if (serverName === 'mds-accounts-staging') {
    return window.location.hostname
    // return 'accounts-staging.moleskinestudio.com'
  }

  // use firebase auth for local dev and other PR builds
  return env.REACT_APP_FIREBASE_AUTHDOMAIN
}

const authDomain = getAuthDomain()

export const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_APIKEY,
  databaseURL: env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  authDomain
}

const getEnv = () => {
  const serverName = env.REACT_APP_HEROKU_APP_NAME || 'local'

  if (serverName === 'local') {
    return 'development'
  } else if (serverName === 'mds-accounts') {
    return 'production'
  } else {
    // serverName == 'mds-accounts-staging'
    return 'staging'
  }
}

export default getEnv
